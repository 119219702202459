import React from 'react'

const LineLoading = () => {
  return (
    <>
    
    <div class="loader-line"></div>
    <p>We Are Processing your payment please wait</p>
    </>
  )
}

export default LineLoading