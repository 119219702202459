import React from 'react'

function VechicleTruckIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="28"
    fill="none"
    viewBox="0 0 36 28"
  >
    <path
      fill="#fff"
      d="M0 3.875A3.375 3.375 0 013.375.5h20.25A3.375 3.375 0 0127 3.875V7.25h2.295a3.375 3.375 0 012.632 1.267l3.333 4.162c.48.6.74 1.344.74 2.11v4.836A3.375 3.375 0 0132.625 23H31.5a4.5 4.5 0 11-9 0H11.25a4.5 4.5 0 11-8.995-.191A3.375 3.375 0 010 19.625V3.875zm2.912 16.776a4.498 4.498 0 017.735.099h12.456a4.522 4.522 0 011.647-1.647V3.875a1.125 1.125 0 00-1.125-1.125H3.375A1.125 1.125 0 002.25 3.875v15.75a1.125 1.125 0 00.662 1.026zM27 18.5a4.5 4.5 0 013.897 2.25h1.728a1.125 1.125 0 001.125-1.125v-4.838c0-.255-.088-.502-.248-.702l-3.33-4.162a1.125 1.125 0 00-.877-.423H27v9zM6.75 20.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm20.25 0a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
    ></path>
  </svg>
  )
}

export default VechicleTruckIcon