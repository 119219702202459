import React from 'react'

function RightArrowIcon(props) {
  const {active}=props
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="15"
    fill="none"
    viewBox="0 0 10 15"
  >
    <path
      fill={`${active? '#656565':'#b9b9b9'}`}
      d="M0 13.238L6.18 7.5 0 1.763 1.903 0 10 7.5 1.903 15 0 13.238z"
    ></path>
  </svg>
  )
}

export default RightArrowIcon