import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dummycompany from '../../public/images/house-g1cc5a9e18_640.png'
import ReactLoading from 'react-loading'

export default function ProfileDetails(props) {
  const { company_name, image, loading } = props

  const { t } = useTranslation()

  const navigate = useNavigate()
  
  return (
    <Row className="no-gutters px-3 py-4 mx-auto">
      <Col>
        <Card
          style={{
            background: 'rgba(49, 100, 244, 0.1)',
            border: 'none',
            padding: '4px',
          }}
        >
          <Col
            className="edit_profile_company_logo position-relative cursor-pointer g-0 mx-auto"
          >
            {!loading?<Card.Img
              variant="top"
              src={image ? image : dummycompany}
              className="company_profile_image"
            />:<ReactLoading type={'spokes'} color="black" className={'loader_position'}/>}
          </Col>
          <Card.Body>
            <Card.Title className="sidebar_content_companyname">
              {company_name}
            </Card.Title>
            <Card.Text
              className="sidebar_content_profile_text"
              onClick={() => navigate('/editprofile')}
            >
              {t('Edit Profile')}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
