import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useLanguageStore } from '../../Store/Store'

function OrderDetails(props) {
  const { numbers, detailsText, icon, color } = props

  const currentLang = useLanguageStore((state) => state.currentLang)
  

  return (
    <Col xs={12} sm={6} lg={6} xl={4}>
      <Row className="align-items-center px-2 py-2 sidbar_content_orders" >
        <Col>
          <h2 className="pt-1">{numbers}</h2>
          <p className="sidbar_content_details_text">{detailsText}</p>
        </Col>
        <Col
          className={`${currentLang === 'ar' ? 'orderdetails_content_display' : ''}`}
        >
          <div
            className="sidbar_content_icons_round"
            style={{ backgroundColor: `${color}` }}
          >
            {icon}
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default OrderDetails
