import React from 'react'

function DeleteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{height:'25px',width:'25px'}}>
      <path d="M24.2 12.193L23.8 24.3a3.988 3.988 0 01-4 3.857h-7.6a3.988 3.988 0 01-4-3.853l-.4-12.111a1 1 0 012-.066l.4 12.11a2 2 0 002 1.923h7.6a2 2 0 002-1.927l.4-12.106a1 1 0 012 .066zm1.323-4.029a1 1 0 01-1 1H7.478a1 1 0 010-2h3.1a1.276 1.276 0 001.273-1.148 2.991 2.991 0 012.984-2.694h2.33a2.991 2.991 0 012.984 2.694 1.276 1.276 0 001.273 1.148h3.1a1 1 0 011 1zm-11.936-1h4.828a3.3 3.3 0 01-.255-.944 1 1 0 00-.994-.9h-2.33a1 1 0 00-.994.9 3.3 3.3 0 01-.256.944zm1.007 15.151V13.8a1 1 0 00-2 0v8.519a1 1 0 002 0zm4.814 0V13.8a1 1 0 00-2 0v8.519a1 1 0 002 0z"></path>
    </svg>
  )
}

export default DeleteIcon