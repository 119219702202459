import React from 'react'

function OrderTableIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="17"
    fill="none"
    viewBox="0 0 22 17"
  >
    <path
      fill="#fff"
      d="M.844 2.094A1.219 1.219 0 012.063.875h17.875a1.219 1.219 0 010 2.438H2.063a1.219 1.219 0 01-1.22-1.22zm3.25 6.5a1.219 1.219 0 011.218-1.219h11.375a1.219 1.219 0 010 2.438H5.313a1.219 1.219 0 01-1.218-1.22zm3.25 6.5a1.219 1.219 0 011.218-1.219h4.876a1.219 1.219 0 010 2.438H8.561a1.219 1.219 0 01-1.218-1.22z"
    ></path>
  </svg>
  )
}

export default OrderTableIcon