import React from 'react'
import { Modal } from 'react-bootstrap'
import CrossIcon from '../svg/CrossIcon'
import ChangePasswordForm from './ChangePasswordForm'
import { useTranslation } from 'react-i18next'
import PasswordImage from '../../public/images/Group.png'

function ChangePasswordModal(props) {
  const { t } = useTranslation()

  const { show, handleClose, closeModal } = props

  return (
    <Modal show={show} onHide={handleClose} className='forgot_password'>
      <div
        closeButton
        className="vechicle_modal_close_button"
        onClick={closeModal}
      >
        <CrossIcon />
      </div>

      <div>
        <div className="d-flex vechicle_modal">
          <div className="">
            <div className="edit_profile_forgot_password">
              <img src={PasswordImage} alt="passimage" loading="lazy"/>
            </div>
          </div>

          <div className="vechicle_modal_add my-2">
            <p>{t('Change password')}</p>
          </div>
          <div className="vechicle_modal_form">
            <ChangePasswordForm handleClose={handleClose}/>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChangePasswordModal
