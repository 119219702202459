import './App.css'
import Login from './Auth/Login'
import 'bootstrap/dist/css/bootstrap.min.css'
import Signup from './Auth/Signup'
import { Route, Routes } from 'react-router-dom'
import Index from './Dashboard/Pages/Index'
import i18n from './i18n'
import { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'react-bootstrap'
import { useLanguageStore } from './Store/Store'
import Confirmation from './Auth/Confirmation'
import EnterPassword from './Auth/EnterPassword'
import ForgotPassword from './Auth/ForgotPassword'
import Reenterpassword from './Auth/Reenterpassword'
import Protected from './Auth/Protected'
import { ToastContainer } from 'react-toastify'
import SuccessComponent from './Dashboard/Components/SuccessComponent'
import ConfirmationForgot from './Auth/ConfirmationForgot'

function App() {

  const [locale, setLocale] = useState(i18n.language)

  i18n.on('languageChanged', (lng) => setLocale(i18n.language))
  
  const changeLanguage = useLanguageStore((state) => state.changeLanguage)

  useEffect(() => {
    changeLanguage(locale)
  }, [changeLanguage, locale])

  console.log('locatle=>',locale);
  return (
    <div className="App">
      <ToastContainer />

      <Suspense >
        <Helmet
          htmlAttributes={{
            lang: locale,
            dir: locale === 'ar'?  'rtl': 'ltr',
          }}
        />
        <ThemeProvider dir={locale === 'ar' ? 'rtl' : 'ltr'}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/confirmation/forgot" element={<ConfirmationForgot/>} />
            <Route path="/enterpassword/:id" element={<EnterPassword />} />
            <Route path="/reenterpassword/:id" element={<Reenterpassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/success" element={<SuccessComponent />} />
            {/* <PrivateRoute path="/home/*" component={Index} /> */}
            <Route path="/*" element={<Protected Component={Index} />} />
          </Routes>
        </ThemeProvider>
      </Suspense>
    </div>
  )
}

export default App
