import React from 'react'
import { Button, Row } from 'react-bootstrap'
import PaymentIconWhite from '../svg/PaymentIconWhite'
import { useTranslation } from 'react-i18next'
function PaymentCard(props) {
  const { t } = useTranslation()

  const {
    orderCount,
    commissionDue,
    totalEarnedMoney,
    handlePaymentSubmit,
    totalPaidAmount,
  } = props

  return (
    <>
      <div className="sidebar_content_round">
        <div
          className="sidbar_content_icons_round vechicle"
          style={{ backgroundColor: `#3164F4` }}
        >
          <PaymentIconWhite />
        </div>
      </div>

      <div className="payment_container">
        <div className="payment_card">
          <h3>{t('Total Orders')} : </h3>
          <h3>{orderCount}</h3>
        </div>

        <div className="payment_card">
          <h3>{t('Commission due for payment')}: </h3>
          <h3>JD {commissionDue}</h3>
        </div>
        {/* <div><h3 className="payment_card_text"> </h3><h3>232</h3></div>
            <h3 className="payment_card_text"> </h3> <h3>232</h3> */}
        <div className="payment_card_prices"></div>
      </div>
    
    </>
  )
}

export default PaymentCard
