import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import VechicleAddIcon from '../svg/VechicleAddIcon'
import { useTranslation } from 'react-i18next'
import CrossIcon from '../svg/CrossIcon'
import PasswordIcon from '../svg/PasswordIcon'
import { useState } from 'react'
import { useLanguageStore } from '../../Store/Store'

const EditVehicleForm = (props) => {
    const {
        handleEditSubmit,
        image,
        imageRef,
        onSelectFile,
        getImage,
        delete_truck_image,
        data
      } = props
      const { t } = useTranslation()
    
      const [passwordOne, setPasswordOne] = useState(false)
    
      const [passwordTwo, setPasswordTwo] = useState(false)
    
      const vehicleData=data.length>0?data[0]:{}
    
      const formik = useFormik({
        initialValues: {
          registration_number: vehicleData.registration_number || '',
          password: vehicleData.vehicle_password || '',
          renter_password:vehicleData.vehicle_password || ''
        },
        enableReinitialize:true,
        validationSchema: Yup.object({
          registration_number: Yup.string()
            .min(1, `${t('Password must be at least 8 characters long')}`)
            .required(`${t('Required')}`),
          password: Yup.string()
          .min(8, `${t('Password must be at least 8 characters long')}`)
          .matches(/[0-9]/, `${t('Password must contain at least one digit')}`)
          .required(`${t('Password is required')}`),
          renter_password: Yup.string().oneOf(
            [Yup.ref('password'), ''],
            `${t('Passwords must match')}`
          ),
         
        }),
        onSubmit: handleEditSubmit,
      })
       
      const current_lang = useLanguageStore((state) => state.currentLang)
    
  return (
    <div>
    <Container className="g-0">
      <Row className="login_content_box no-gutters row-fluid g-0">
        <Row className="vechicle_modal_row g-0">
          <Col>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={t('Vehicle Reg. no.')}
                  id="registration_number"
                  value={formik.values.registration_number}
                  onChange={formik.handleChange}
                  autocomplete="off"
                />
                {formik.touched.registration_number &&
                formik.errors.registration_number ? (
                  <div className="form_errors">
                    {formik.errors.registration_number}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 position-relative">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control
                  type={passwordOne ? 'text' : 'password'}
                  id="password"
                  placeholder={t('Password')}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  autocomplete="off"
                />
                <div
                 className={
                  current_lang === 'ar' 
                    ? `toggle_password_right`
                    : `toggle_password`
                }
                  onClick={() => setPasswordOne((prev) => !prev)}
                >
                  <PasswordIcon color={passwordOne ? '#B3B3B3' : 'black'} />
                </div>

                {formik.touched.password && formik.errors.password ? (
                  <div className="form_errors">{formik.errors.password}</div>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 position-relative">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control
                  type={passwordTwo ? 'text' : 'password'}
                  id="renter_password"
                  placeholder={t('Re-enter Password')}
                  value={formik.values.renter_password}
                  onChange={formik.handleChange}
                />
                <div
                 className={
                  current_lang === 'ar' 
                    ? `toggle_password_right`
                    : `toggle_password`
                }
                  onClick={() => setPasswordTwo((prev) => !prev)}
                >
                  <PasswordIcon color={passwordTwo ? '#B3B3B3' : 'black'} />
                </div>
                {formik.touched.renter_password &&
                formik.errors.renter_password ? (
                  <div className="form_errors">
                    {formik.errors.renter_password}
                  </div>
                ) : null}
              </Form.Group>
              <div
                className="mb-3 vechicle_image_upload_container"
                onClick={getImage}
              >
                {/* <Form.Label>Password</Form.Label> */}
                {!image.imageFile ? (
                  <div className="vechicle_image_upload">
                    <div
                      className="vechicle_image_upload_icon"
                      style={{ backgroundColor: `#3164F4` }}
                    >
                      <input
                        className="form-control d-none"
                        type="file"
                        id="formFileMultiple"
                        multiple
                        ref={imageRef}
                        onChange={onSelectFile}
                      />
                      <VechicleAddIcon />
                    </div>
                    <div className="vechicle_image_upload_container">
                      {' '}
                      <p className="vechicle_image_upload_text">
                        {t('Upload number plate of truck')}
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="vehicle_number_plate">
                      <div
                        className="cross_icon"
                        onClick={delete_truck_image}
                      >
                        <CrossIcon />
                      </div>
                      <div>
                        <img src={image.imageFile} alt="vehicleimage" />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <Button
                variant="primary"
                type="submit"
                className="w-100 my-1 add_vechicle_submit_button"
                // disabled={isSubmitting}
              >
                {t('Submit')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Row>
    </Container>
  </div>
  )
}

export default EditVehicleForm