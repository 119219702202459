import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import { Vechicletables } from '../Components/Vechicletables'
import AddVechicleModal from '../Components/AddVechicleModal'
import Usehook from '../../customhooks.js/usehook'
import {
  deleteVehicle,
  getCompanyVehicle,
  getvehicle,
  updateVehicle,
} from '../../api'
import { toast } from 'react-toastify'
import { addvehicle, deleteimage, uploadimage } from '../../api'
import ReactLoading from 'react-loading'
import EditVehicleModal from '../Components/EditVehicleModal'
import DeleteModal from '../Components/DeleteModal'
import { useNavigate } from 'react-router-dom'

const VechicleIndex = () => {
  const [show, setShow] = useState(false)

  const [showEdit, setShowEdit] = useState(false)

  const [showDelete, setShowDelete] = useState(false)

  const [image, setImage] = useState({ id: '', imageFile: '' })

  const [vechicleData, setVehicleData] = useState([])

  const [loading, setLoading] = useState(true)

  const [vehicleId, setVehicleId] = useState('')

  const [editData, setEditData] = useState([])

  const [deleteVehicleId, setDeleteVehicleId] = useState('')

  const navigate = useNavigate()

  const handleClose = () => {
    setShow(false)

    setImage({ ...image, id: '', imageFile: '' })
  }

  const handleCloseEdit = () => setShowEdit(false)

  const handleCloseDelete = () => setShowDelete(false)

  const { t } = useTranslation()

  useMemo(() => {
    const getData = async () => {
      try {
        const response = await getvehicle()
        if (response) {
          setVehicleData(response.data.data)

          setLoading(false)
        }
      } catch (err) {
        console.log(err)
        if (err.response.status === 401) {
          setLoading(false)
          localStorage.removeItem('access_token')
          navigate('/login')
        }
      }
    }
    getData()
    // setVehicleData(data)
  }, [showEdit])

  const imageRef = useRef(null)

  const getImage = () => {
    imageRef?.current?.click()
  }

  const onSelectFile = async (e) => {
    const image = e.target.files[0]

    const formData = new FormData()

    formData.append('image', image)

    formData.append('field', 'vehicle_images')

    if (formData) {
      try {
        const results = await toast.promise(
          uploadimage(formData, 'geeb_8aaz_vehicle_images'),
          {
            pending: 'Loading',
          }
        )
        setImage({
          ...image,
          id: results.data.data.vec_id,
          imageFile: results.data.data.image,
        })

        if (results.data.status === 0) {
          toast.error(results.data.message, {
            position: 'top-center',
          })
        } else if (results.data.status === 1) {
          toast.success(results.data.message, {
            position: 'top-center',
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
  const delete_truck_image = async () => {
    const formData = new FormData()

    formData.append('field', 'vehicle_images')

    const image_id = image.id
    try {
      const results = await deleteimage(
        formData,
        'geeb_8aaz_vehicle_images',
        image_id
      )
      if (results.data.status === 0) {
        toast.error(results.data.message, {
          position: 'top-center',
        })
      } else if (results.data.status === 1) {
        setImage({ ...image, imageFile: '', id: '' })
        toast.success(results.data.message, {
          position: 'top-center',
        })
      }
    } catch (err) {
      toast.error('Something Went Wrong Try Again', {
        position: 'top-center',
      })
    }
  }
  const handleSubmit = async (values, { resetForm }) => {
    if (!image.id && !image.imageFile) {
      toast.error('Please Upload Vehicle Image', {
        position: 'top-center',
      })
      return
    }
    try {
      let data = { ...values, vehicle_id: image.id }
      delete data.renter_password

      const results = await toast.promise(addvehicle(data), {
        pending: 'Loading',
      })
      if (results.data.status === 0) {
        toast.error(results.data.message, {
          position: 'top-center',
          autoClose: 5000,
        })
      } else if (results.data.status === 1) {
        setVehicleData([...vechicleData, results.data.data])

        toast.success(results.data.message, {
          position: 'top-center',
        })
        handleClose()
        setImage({ ...image, imageFile: '', id: '' })
        resetForm()
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: 'top-center',
        autoClose: 5000,
      })
    }
  }
  const handleEditSubmit = async (values, { resetForm }) => {
    if (!image.id && !image.imageFile) {
      toast.error('Please Upload Vehicle Image', {
        position: 'top-center',
      })
      return
    }
    try {
      let data = {
        ...values,
        vehicle_password: values.password,
        imageId: image.id,
      }
      delete data.renter_password

      delete data.password

      const results = await toast.promise(updateVehicle(data, vehicleId), {
        pending: 'Loading',
      })
      if (results.data.status === 0) {
        toast.error(results.data.message, {
          position: 'top-center',
        })
      } else if (results.data.status === 1) {

        const updatedData = vechicleData.map((vehicleinfo) => {
          if (vehicleinfo.v_id === vehicleId) {
            return {
              ...vehicleinfo,
              registration_number: values.registration_number,
            }
          }
          return vehicleinfo
        })

        setVehicleData(updatedData)

        toast.success(results.data.message, {
          position: 'top-center',
        })

        handleCloseEdit()
        
        setImage({ ...image, imageFile: '', id: '' })
        
        resetForm()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleEditClick = async (id) => {
    setShowEdit(true)

    try {
      const data = await getCompanyVehicle(id)

      setVehicleId(id)

      if (data.data.status === 1) {
        const response = data.data.data

        setImage({
          ...image,
          id: response[0].vehicle_image_id,
          imageFile: response[0].vehicle_image,
        })

        setEditData(response)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handleDeleteClick = (id) => {
    setDeleteVehicleId(id)

    setShowDelete(true)
  }

  const handleDeleteConfirm = async () => {
    try {
      const results = await deleteVehicle(deleteVehicleId)

      if (results.data.status === 0) {
        toast.error(results.data.message, {
          position: 'top-center',
        })
      } else if (results.data.status === 1) {
        let filteredVehicle = vechicleData.filter(
          (vehicle) => vehicle.v_id !== deleteVehicleId
        )

        setVehicleData(filteredVehicle)

        toast.success(results.data.message, {
          position: 'top-center',
        })

        handleCloseDelete()
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <AddVechicleModal
        show={show}
        closeModal={handleClose}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        image={image}
        imageRef={imageRef}
        onSelectFile={onSelectFile}
        getImage={getImage}
        delete_truck_image={delete_truck_image}
      />

      <EditVehicleModal
        show={showEdit}
        closeModal={handleCloseEdit}
        handleClose={handleCloseEdit}
        handleEditSubmit={handleEditSubmit}
        data={editData}
        image={image}
        imageRef={imageRef}
        onSelectFile={onSelectFile}
        getImage={getImage}
        delete_truck_image={delete_truck_image}
      />

      <DeleteModal
        show={showDelete}
        handleClose={handleCloseDelete}
        handleDeleteConfirm={handleDeleteConfirm}
        closeLogoutModal={() => setShowDelete(false)}
      />

      {!loading ? (
        <Container
          className="dashboard_main_screen_vechicle_table animated_index"
          fluid
        >
          <Vechicletables
            openAddVechicle={() => setShow(true)}
            registrationno={t('Registration no.')}
            password={t('Password')}
            addvechicle={t('Add Vehicle')}
            vechicle={t('Vehicles')}
            serialnumber={t('Sr. No.')}
            novehiclefound={t('No vehicle found')}
            itemperpage={t('Items Per Page')}
            of={t('Of')}
            approved={t('Approved')}
            data={vechicleData}
            loading={loading}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            edit={t('Edit')}
          />
        </Container>
      ) : (
        <ReactLoading
          type={'spokes'}
          color="black"
          className={'loader_position'}
        />
      )}
    </>
  )
}

export default VechicleIndex
