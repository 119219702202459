import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import SuccessGif from '../../public/images/Success.gif'
import { useNavigate } from 'react-router-dom'

const SuccessComponent = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <Container className="animated_index" fluid>
      <div className="confirmation_page">
        <div className="confirmation_card">
          <div className="confirmation_content">
            <div className="confirmation_mail_image">
              <img src={SuccessGif} alt="confiration Mail" loading="lazy" />
            </div>
            <div className="confirmation_text">
              <p>Your Payment Has Been Successful</p>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="w-100 my-1 add_vechicle_submit_button payment_card_button"
              onClick={() => navigate('/')}
            >
              {t('Go To Home')}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SuccessComponent
