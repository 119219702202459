import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import LeftArrowIcon from '../svg/LeftArrowIcon'
import RightArrowIcon from '../svg/RightArrowIcon'
import './vechicletable.css'
import OrderTableIcon from '../svg/OrderTableIcon'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import uniqid from 'uniqid'
import { Form } from 'react-bootstrap'

class Pagination extends React.Component {
  constructor(props, context) {
    super(props, context)
    // this.incrementCurrentPage = this.incrementCurrentPage.bind(this)
    this.state = {
      currentPage: null,
      pageCount: null,
      pageSize: 10,
      leftActive: false,
      rightActive: false,
    }
  }
  componentDidMount() {
    if (this.state.pageCount > 1) {
      this.setState({ rightActive: true })
    }
  }
  componentWillMount() {
    const startingPage = this.props.startingPage ? this.props.startingPage : 1
    const data = this.props.data
    const pageSize = this.state.pageSize

    let pageCount = parseInt(data.length / pageSize)

    if (data.length % pageSize > 0) {
      pageCount++
    }
    this.setState({
      currentPage: startingPage,
      pageCount: pageCount,
    })
  }

  setCurrentPage(num) {
    this.setState({ currentPage: num })
  }

  decrementCurrentPage() {
    this.setState((prevState) => {
      if (prevState.currentPage > 1) {
        return { currentPage: prevState.currentPage - 1 };
      }
      return null; // No need to update state
    });

    if (this.state.currentPage <= 2) {
      this.setState({ leftActive: false })

      this.setState({ rightActive: true })
    }

    if (this.state.pageCount > 1) {
      this.setState({ rightActive: true })
    }
    
  }
  incrementCurrentPage() {
    // const totalPages = this.props.data
    // const totalPagesLength = totalPages.length
    if (this.props.data.length < 10) {
      return
    }
    let currentPage = this.state.currentPage + 1

    let totalPage = this.state.pageCount

    if (this.state.pageCount > this.state.currentPage) {
      this.setCurrentPage(this.state.currentPage + 1)
    }

    if (currentPage >= totalPage) {
      this.setState({ rightActive: false })
    }

    if (currentPage >= 1) {
      this.setState({ leftActive: true })
    }
    this.props.onHandlePageCount(currentPage)
  }
  changePageValue(e) {
    this.setState({ pageSize: e.target.value })
  }
  createControls() {
    let controls = []
    const pageSize = this.state.pageSize
    const totalPages = this.props.data
    const totalPagesLength = totalPages.length
    let firstText = this.state.currentPage
    let totalPageLeft = Math.round((pageSize / pageSize) * firstText)
    let totalPageRight = firstText * pageSize
    if (totalPageRight > totalPagesLength) {
      totalPageRight = totalPagesLength
    }

    this.props.onHandlePageCount(firstText)
    
    controls.push(
      <div className="" key={uniqid()}>
        <div>
          {/* <p className="dashboard_main_screen_vechicle_table_pagination_items">
            {this.props.itemperpage}{' '}
            <span className="dashboard_main_screen_vechicle_table_pagination_page_size">
              <input
                type="text"
                value={this.state.pageSize}
                onChange={(e) => this.changePageValue(e)}
              />
            </span>
          </p> */}
        </div>
        <div className="dashboard_main_screen_vechicle_table_pagination_buttons">
          <p role="button" onClick={() => this.decrementCurrentPage()}>
            <span className={`px-2`}>
              <LeftArrowIcon active={this.state.leftActive} />
            </span>
          </p>
          <p>
            {totalPageLeft}-{totalPageRight} {this.props.of} {totalPagesLength}
          </p>
          <p role="button" onClick={() => this.incrementCurrentPage()}>
            <span className={`px-2`}>
              <RightArrowIcon active={this.state.rightActive} />
            </span>
          </p>
        </div>
      </div>
    )
    return controls
  }

  createPaginatedData() {
    const data = this.props.data
    const pageSize = this.state.pageSize
    const currentPage = this.state.currentPage
    const upperLimit = currentPage * pageSize
    const dataSlice = data.slice(upperLimit - pageSize, upperLimit)
    return dataSlice
  }

  render() {
    return (
      <>
        <div className="pagination">
          <div className="pagination-results">
            {React.cloneElement(this.props.children, {
              data: this.createPaginatedData(),
            })}
          </div>
          {this.props.data.length > 0 ? (
            <div className="pagination-controls">{this.createControls()}</div>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  }
}

//   Pagination.propTypes = {
//     data: React.PropTypes.array.isRequired,
//     pageSize: React.PropTypes.number.isRequired,
//     startingPage: React.PropTypes.number.isRequired
//   };

Pagination.defaultProps = {
  pageSize: 10,
  startingPage: 1,
}

class Example extends React.Component {
  render() {
    const data = this.props.data
    const {
      orderId,
      orderDate,
      status,
      totalPayment,
      vechicleRegistrationno,
      orders,
      serialnumber,
      noorderfound,
      processing,
      completed,
      cancelled,
      search,
      searchValue,
      handleSearchValues,
      page,
    } = this.props
    return (
      <Row className="bg-white p-2 ">
        <div className="d-flex justify-content-between  align-items-center order_header_row ">
          <div className="sidebar_content_orders order_headers">{orders}</div>
          <div className="sidebar_content_orders_search_field order_headers">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder={search}
                id="registration_number"
                value={this.props.searchValue}
                onChange={this.props.handleSearchValues}
                autocomplete="off"
              />
            </Form.Group>
          </div>

          {/* <div className="sidebar_content_view_all">
            <button
              className="sidebar_content_view_all_button orders_table_icon"
              onClick={this.props.openAddVechicle}
            >
              <span>
                <OrderTableIcon />
              </span>
            </button>
          </div> */}
        </div>

        <Table responsive="xl">
          {data.length > 0 ? (
            <>
              <thead>
                <tr className="sidbar_content_table_other_row dashboard_vechicle_table">
                  <th style={{ width: '7%' }}>{serialnumber}</th>
                  <th style={{ width: '15%' }}>{orderId}</th>
                  <th style={{ width: '15%' }}>{orderDate}</th>
                  <th style={{ width: '15%' }}>{vechicleRegistrationno}</th>
                  <th style={{ width: '15%' }}>{totalPayment}</th>
                  <th style={{ textAlign: 'left', paddingLeft: '40px' }}>
                    {status}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr
                      className="sidbar_content_table_other_row dashboard_vechicle_table"
                      key={index}
                    >
                      <td>{(page - 1) * 10 + index + 1}</td>
                      <td>{item.order_id}</td>
                      <td style={{ textAlign: 'left', paddingLeft: '40px' }}>
                        {moment(item.time * 1000).format('D MMM. YYYY')}
                        <br />
                      </td>
                      <td>{item.registration_number}</td>
                      <td>{item.order_total}</td>
                      <td
                        style={{
                          textAlign: 'left',
                          paddingLeft: '40px',
                        }}
                      >
                        <span
                          style={{
                            background: `${
                              item.order_status === 'Processing'
                                ? '#3164F41A'
                                : item.order_status === 'Cancelled'
                                ? '#E20D0D1A'
                                : item.order_status === 'Completed'
                                ? '#13A7341A'
                                : 'gray'
                            }`,
                            color: `${
                              item.order_status === 'Processing'
                                ? '#3164F4'
                                : item.order_status === 'Cancelled'
                                ? '#E20D0D'
                                : item.order_status === 'Completed'
                                ? '#13A734'
                                : 'white'
                            }`,
                          }}
                          className="dashboard_order_status"
                        >
                          {' '}
                          {item.order_status === 'Processing'
                            ? processing
                            : item.order_status === 'Cancelled'
                            ? cancelled
                            : item.order_status === 'Completed'
                            ? completed
                            : ''}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </>
          ) : (
            <thead>
              <tr>
                <td colSpan="3" className="empty_table_data">
                  {noorderfound}
                </td>
              </tr>
            </thead>
          )}
        </Table>
      </Row>
    )
  }
}
export default withTranslation()(Example)
export function Orderstable(props) {
  const {
    orders,
    vechicle,
    itemperpage,
    orderId,
    orderDate,
    status,
    totalPayment,
    vechicleRegistrationno,
    noorderfound,
    serialnumber,
    of,
    search,
    processing,
    cancelled,
    completed,
    searchValue,
    handleSearchValues,
    data,
  } = props

  const [page, setPage] = useState(1)

  const onHandlePageCount = (count) => {
    setPage(count)
  }
  return (
    <Pagination
      data={data}
      of={of}
      itemperpage={itemperpage}
      onHandlePageCount={onHandlePageCount}
    >
      <Example
        orders={orders}
        //  openAddVechicle={openAddVechicle}
        processing={processing}
        cancelled={cancelled}
        completed={completed}
        itemperpage={itemperpage}
        orderId={orderId}
        orderDate={orderDate}
        status={status}
        totalPayment={totalPayment}
        noorderfound={noorderfound}
        vechicleRegistrationno={vechicleRegistrationno}
        vechicle={vechicle}
        serialnumber={serialnumber}
        search={search}
        page={page}
        searchValue={searchValue}
        handleSearchValues={handleSearchValues}
      />
    </Pagination>
  )
}
