import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Logo from '../public/images/image 1.png'
import './login.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { signUpUser } from '../api'
import { toast } from 'react-toastify'
import PasswordIcon from '../Dashboard/svg/PasswordIcon'
import CountryList from 'country-list-with-dial-code-and-flag'
import { useLanguageStore } from '../Store/Store'

function Signup() {
  const { t } = useTranslation()

  let navigate = useNavigate()

  const [passwordOne, setPasswordOne] = useState(false)

  // const [dropInput, setDropInput] = useState(0)

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formik = useFormik({
    initialValues: {
      name: '',
      company_name: '',
      email: '',
      dialcode: '',
      phone_number: '',
      password: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(4, `${t('Name must have 4 characters')}`)
        .max(20, `${t('Name must have 20 character or less')}`)
        .required(`${t('Required')}`),
      company_name: Yup.string()
        .max(30, `${t('يجب أن يتكون اسم الشركة من 30 حرفًا أو أقل')}`)
        .required(`${t('Required')}`),
      email: Yup.string()
        .email(`${t('Invalid email address')}`)
        .required(`${t('Required')}`),
      phone_number: Yup.string()
        .matches(phoneRegExp, `${t('Phone number is not valid')}`)
        .min(10, `${t('Phone number is not valid')}`)
        .max(10, `${t('Phone number is not valid')}`),
      dialcode: Yup.string().required(`${t('Please select an option')}`),
      password: Yup.string()
        .min(8, `${t('Password must be at least 8 characters long')}`)
        .matches(/[0-9]/, `${t('Password must contain at least one digit')}`)
        .required(`${t('Password is required')}`),
    }),
    onSubmit: async (values) => {
      try {
        const user_data = new Map()
        user_data.set('company_name', values.company_name)
        user_data.set('phone_number', values.phone_number)
        user_data.set('email', values.email)
        user_data.set(
          'phone_number',
          `${values.dialcode}${values.phone_number}`
        )
        user_data.set('password', values.password)
        user_data.set('name', values.name)
        user_data.set('user_role', 'COMPANY')
        const send_user_data = Object.fromEntries(user_data.entries())

        const data = await toast.promise(signUpUser(send_user_data), {
          pending: 'Loading',
        })
        if (data.data.status === 0) {
          toast.error(data.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
            progressClassName: 'fancy-progress-bar',
          })
        } else if (data.data.status === 1) {
          navigate('/confirmation')
          sessionStorage.setItem('user_email', values.email)
        }
      } catch (err) {
        console.log(err)
      }
    },
  })

  const all_countries = CountryList.getAll()

  const current_lang = useLanguageStore((state) => state.currentLang)


  return (
    <div className="login animated_auth">
      <Container fluid="md login_container">
        <Row className="login_content_box g-0 row-fluid px-4">
          <Row className="g-0">
            <Col className="signup_container_logo">
              <img src={Logo} alt="" loading="lazy" />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="name"
                    placeholder={t('Name')}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="form-control"
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="form_errors">{formik.errors.name}</div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="company_name"
                    placeholder={t('Company Name')}
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.company_name && formik.errors.company_name ? (
                    <div className="form_errors">
                      {formik.errors.company_name}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder={t('Email')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="form_errors">{formik.errors.email}</div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 position-relative">
                  <Form.Select
                    aria-label="Default select example"
                    className="signup_drop_down_menu"
                    onChange={formik.handleChange}
                    id="dialcode"
                  >
                    <option value="" key={'0'}>{t('Select')}</option>
                    {all_countries.map((countries, index) => {
                      return (
                        <>
                          <option
                            value={countries.data.dial_code}
                            key={index+1}
                            className="countries_code_select"
                          >
                            {countries.data.name}{' '}
                          </option>
                        </>
                      )
                    })}
                  </Form.Select>
                  <Form.Control
                    type="text"
                    id="phone_number"
                    placeholder={t('Phone Number')}
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    className={`
                      w-75 signup_phone_number ${
                        current_lang === 'ar'
                          ? `signup_phone_number_right`
                          : `signup_phone_number_left`
                      } `}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="form_errors">
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                  {formik.touched.dialcode && formik.errors.dialcode ? (
                    <div className="form_errors">{formik.errors.dialcode}</div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 position-relative">
                  <Form.Control
                    type={passwordOne ? 'text' : 'password'}
                    id="password"
                    placeholder={t('Password')}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  <div
                    className={
                      current_lang === 'ar'
                        ? `toggle_password_right`
                        : `toggle_password`
                    }
                    onClick={() => setPasswordOne((prev) => !prev)}
                  >
                    <PasswordIcon color={passwordOne ? '#B3B3B3' : 'black'} />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="form_errors">{formik.errors.password}</div>
                  ) : null}
                </Form.Group>
                <div
                  className="text-right login_container_forgotpassword"
                  onClick={() => navigate('/forgotpassword')}
                >
                  <p>{t('Forgot Password ?')}</p>
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 my-1 login_container_button"
                >
                  {t('Signup')}
                </Button>

                <div className="py-2 mx-auto text-center login_container_text">
                  <p>
                    {t('Already have an account ?')}{' '}
                    <span
                      className="login_container_signup"
                      onClick={() => navigate('/')}
                    >
                      {t('Login')}
                    </span>
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  )
}

export default Signup
