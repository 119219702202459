import React from 'react'

function ExitIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
  >
    <path
      fill="#E20D0D"
      fillRule="evenodd"
      d="M9.308 0a4.441 4.441 0 014.436 4.436v.932a.75.75 0 01-1.5 0v-.932A2.939 2.939 0 009.308 1.5H4.433A2.937 2.937 0 001.5 4.436v11.129A2.937 2.937 0 004.433 18.5H9.32a2.929 2.929 0 002.925-2.924v-.943a.75.75 0 011.5 0v.943A4.43 4.43 0 019.32 20H4.433A4.439 4.439 0 010 15.565V4.436A4.44 4.44 0 014.433 0h4.875zm8.08 6.554l2.928 2.915c.027.026.05.053.071.081l-.07-.081a.73.73 0 01.217.464.665.665 0 01.003.067l-.005.062-.002.04v.005l.007-.107a.754.754 0 01-.227.538l-2.922 2.909a.747.747 0 01-1.06-.002.75.75 0 01.002-1.06l1.64-1.635H7.746a.75.75 0 010-1.5h10.226L16.33 7.616a.749.749 0 111.058-1.062z"
      clipRule="evenodd"
    ></path>
    </svg>
  )
}

export default ExitIcon