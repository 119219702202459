import React from 'react'

function PaymentIconWhite() {
  return (
    <div>    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="30"
    fill="none"
    viewBox="0 0 31 30"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M21.962.5c4.96 0 8.996 4.035 8.996 8.997v10.765c0 4.961-4.035 8.997-8.996 8.997H8.996C4.036 29.26 0 25.224 0 20.262V9.497C0 4.535 4.036.5 8.996.5h12.966zm0 2.25H8.996A6.755 6.755 0 002.25 9.497v10.765a6.755 6.755 0 006.747 6.747h12.966a6.754 6.754 0 006.746-6.747v-.42l-4.947.001a5.169 5.169 0 01-5.163-5.158 5.171 5.171 0 015.163-5.165h4.947v-.023a6.754 6.754 0 00-6.746-6.747zm6.746 9.02H23.76a2.918 2.918 0 00-2.913 2.913 2.916 2.916 0 002.913 2.91h4.947V11.77zm-4.26 1.695a1.125 1.125 0 010 2.25h-.469a1.125 1.125 0 010-2.25h.468zm-8.421-6.158a1.125 1.125 0 010 2.25H7.928a1.125 1.125 0 010-2.25h8.099z"
      clipRule="evenodd"
    ></path>
  </svg></div>
  )
}

export default PaymentIconWhite