import React, { useEffect, useState } from 'react'
import { Orderstable } from '../Components/OrdersTable'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { getorders } from '../../api'
import ReactLoading from 'react-loading'
import { useNavigate } from 'react-router-dom'

function OrdersIndex() {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')

  const [orderData, setOrderData] = useState([])

  const [data,setData]=useState([])

  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(()=>{
    const getData = async () => {
      try {
        const response = await getorders()
        if (response) {
          setData(response.data.data)
          setOrderData(response.data.data)

          setLoading(false)
        }
      } catch (err) {
        console.log(err)
        if (err.response.status === 401) {
          setLoading(false)
          localStorage.removeItem('access_token')
          navigate('/login')
        }
      }
    }
    getData()
  },[])

  const handleSearchValues = (e) => {
    setSearchValue(e.target.value)

    if (searchValue.length > 1) {
      const filteredData = orderData.filter((order) =>
        order.order_id.includes(e.target.value)
      )

      setOrderData(filteredData)
    }
    else{
      setOrderData(data)

    }
  }
  return (
    <>
      {!loading ? (
        <Container
          className="dashboard_main_screen_vechicle_table animated_index"
          fluid
        >
          <Orderstable
            orders={t('Orders')}
            vechicle={t('Vehicles')}
            itemperpage={t('Items Per Page')}
            orderId={t('Order ID')}
            orderDate={t('Order Date')}
            status={t('Status')}
            totalPayment={t('Total Payment')}
            vechicleRegistrationno={t('Vehicle Register No.')}
            serialnumber={t('Sr. No.')}
            noorderfound={t('No current order')}
            of={t('Of')}
            data={orderData}
            processing={t('Processing')}
            completed={t('Completed')}
            cancelled={t('Cancelled')}
            searchValue={searchValue}
            handleSearchValues={handleSearchValues}
            search={t('Search order id')}
          />
        </Container>
      ) : (
        <ReactLoading
          type={'spokes'}
          color="black"
          className={'loader_position'}
        />
      )}
    </>
  )
}

export default OrdersIndex
