import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import LeftArrowIcon from '../svg/LeftArrowIcon'
import RightArrowIcon from '../svg/RightArrowIcon'
import './vechicletable.css'
import AddIcon from '../svg/AddIcon'
import uniqid from 'uniqid'
import DeleteIcon from '../svg/DeleteIcon'
import EditIcons from '../svg/EditIcons'

class Pagination extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      currentPage: null,
      pageCount: null,
      pageSize: 10,
      leftActive: false,
      rightActive: false,
    }
  }
  componentDidMount() {
    if (this.state.pageCount > 1) {
      this.setState({ rightActive: true })
    }
  }

  componentWillMount() {
    const startingPage = this.props.startingPage ? this.props.startingPage : 1
    const data = this.props.data
    const pageSize = this.state.pageSize
    let pageCount = parseInt(data.length / pageSize)

    if (data.length % pageSize > 0) {
      pageCount++
    }
    this.setState({
      currentPage: startingPage,
      pageCount: pageCount,
    })
  }

  setCurrentPage(num) {
    this.setState({ currentPage: num })
  }

  decrementCurrentPage() {
    if (this.state.currentPage > 1) {
      this.setCurrentPage(this.state.currentPage - 1)
    }

    if (this.state.currentPage <= 2) {
      this.setState({ leftActive: false })
      this.setState({ rightActive: true })
    }
  }
  incrementCurrentPage() {
    // const totalPages = this.props.data
    // const totalPagesLength = totalPages.length
    if (this.props.data.length < 10) {
      return
    }
    let currentPage = this.state.currentPage + 1

    let totalPage = this.state.pageCount

    if (this.state.pageCount > this.state.currentPage) {
      this.setCurrentPage(this.state.currentPage + 1)
    }

    if (currentPage >= totalPage) {
      this.setState({ rightActive: false })
    }

    if (currentPage >= 1) {
      this.setState({ leftActive: true })
    }
  }
  changePageValue(e) {
    this.setState({ pageSize: e.target.value })
  }
  createControls() {
    let controls = []
    const pageSize = this.state.pageSize
    const totalPages = this.props.data
    const totalPagesLength = totalPages.length
    let firstText = this.state.currentPage
    let totalPageLeft = Math.round((pageSize / pageSize) * firstText)
    let totalPageRight = firstText * pageSize
    if (totalPageRight > totalPagesLength) {
      totalPageRight = totalPagesLength
    }
    
    this.props.onHandlePageCount(firstText)

    controls.push(
      <div
        className="dashboard_main_screen_vechicle_table_pagination_controls"
        key={uniqid()}
      >
        <div className="dashboard_main_screen_vechicle_table_pagination_buttons">
          <p role="button" onClick={() => this.decrementCurrentPage()}>
            <span className={`px-2`}>
              <LeftArrowIcon active={this.state.leftActive} />
            </span>
          </p>
          <p>
            {totalPageLeft}-{totalPageRight} {this.props.of} {totalPagesLength}
          </p>
          <p role="button" onClick={() => this.incrementCurrentPage()}>
            <span className={`px-2`}>
              <RightArrowIcon active={this.state.rightActive} />
            </span>
          </p>
        </div>
      </div>
    )

    return controls
  }

  createPaginatedData() {
    const data = this.props.data
    const pageSize = this.state.pageSize
    const currentPage = this.state.currentPage
    const upperLimit = currentPage * pageSize
    const dataSlice = data.slice(upperLimit - pageSize, upperLimit)
    return dataSlice
  }

  render() {
    return (
      <div className="pagination">
        <div className="pagination-results">
          {React.cloneElement(this.props.children, {
            data: this.createPaginatedData(),
          })}
        </div>
        {this.props.data.length > 0 ? (
          <div className="pagination-controls">{this.createControls()}</div>
        ) : (
          <></>
        )}
      </div>
    )
  }
}

Pagination.defaultProps = {
  pageSize: 10,
  startingPage: 1,
}

class Example extends React.Component {
  render() {
    const data = this.props.data
    const {
      registrationno,
      password,
      addvechicle,
      vechicle,
      serialnumber,
      novehiclefound,
      page,
      handleEditClick,
      handleDeleteClick,
      edit
    } = this.props
    return (
      <Row className="bg-white p-2 ">
        <div className="d-flex justify-content-between align-items-center order_header_row">
          <div className="sidebar_content_orders">{vechicle}</div>
          <div className="sidebar_content_view_all">
            <button
              className="sidebar_content_view_all_button"
              onClick={this.props.openAddVechicle}
            >
              <span className="px-2">
                <AddIcon />
              </span>
              {addvechicle}
            </button>
          </div>
        </div>

        <Table responsive="lg">
          {data.length > 0 ? (
            <>
              <thead>
                <tr className="sidbar_content_table_other_row">
                  <th style={{ width: '7%' }}>{serialnumber}</th>
                  <th style={{ width: '15%' }}>{registrationno}</th>
                  <th style={{ textAlign: 'left', paddingLeft: '40px' }}>
                    {password}
                  </th>
                  <th style={{ textAlign: 'left', paddingLeft: '40px' }}>
                    {"Aprroved"}
                  </th>
                  <th style={{ textAlign: 'left', paddingLeft: '40px' }}>
                    {edit}
                  </th>
                 
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr
                      className="sidbar_content_table_other_row"
                      key={uniqid()}
                    >
                      <td key={uniqid()}>{(page - 1) * 10 + index + 1}</td>
                      <td key={uniqid()}>{item.registration_number}</td>
                      <td
                        style={{
                          textAlign: 'left',
                          paddingLeft: '40px',
                          width: '209px',
                        }}
                      >
                        {item.vehicle_password}
                      </td>
                      <td
                        style={{
                          textAlign: 'left',
                          paddingLeft: '40px',
                          width: '209px',
                        }}
                      >
                         <span
                        className="dashboard_order_status"
                        style={{
                          background: `${
                            item.is_approved===1 ? '#13A7341A': '#E20D0D1A'}`,
                          color:`${
                            item.is_approved===1 ? '#13A734': '#E20D0D'
                          }`
                        }}
                      >
                        {item.is_approved===1?'Approved':'Disapprove'}
                      
                      </span>
                      </td>
                      <td className="d-flex cursor-pointer">
                        <p onClick={()=>handleEditClick(item.v_id)}>
                          <EditIcons />
                        </p>{' '}
                        <p onClick={()=>handleDeleteClick(item.v_id)}>
                          <DeleteIcon />{' '}
                        </p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </>
          ) : (
            <thead>
              <tr>
                <td colSpan="3" className="empty_table_data">
                  {novehiclefound}
                </td>
              </tr>
            </thead>
          )}
        </Table>
      </Row>
    )
  }
}

export function Vechicletables(props) {
  const {
    openAddVechicle,
    registrationno,
    password,
    addvechicle,
    vechicle,
    itemperpage,
    serialnumber,
    novehiclefound,
    of,
    data,
    handleEditClick,
    handleDeleteClick,
    edit

  } = props

  const [page, setPage] = useState(1)

  const onHandlePageCount = (count) => {
    setPage(count)
  }
  return (
    <>
      <Pagination
        data={data}
        of={of}
        itemperpage={itemperpage}
        onHandlePageCount={onHandlePageCount}
      >
        <Example
          openAddVechicle={openAddVechicle}
          password={password}
          addvechicle={addvechicle}
          registrationno={registrationno}
          vechicle={vechicle}
          serialnumber={serialnumber}
          novehiclefound={novehiclefound}
          page={page}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          edit={edit}
          //  itemperpage={itemperpage}
        />
      </Pagination>
    </>
  )
}
