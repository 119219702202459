import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { reenterpassword, resettoken } from '../api/index'
import PasswordIcon from '../Dashboard/svg/PasswordIcon'
import Logo from '../public/images/image 1.png'
import { useNavigate, useParams } from 'react-router-dom'
import NotFoundError from '../Dashboard/Components/NotFoundError'

function Reenterpassword() {
  const { t } = useTranslation()

  const { id } = useParams()

  const [error, setError] = useState(false)

  const navigate=useNavigate()

  useEffect(() => {
    const getTokenData = async (id) => {
      try{
        const result_reset_token = await resettoken(id)
        if (!result_reset_token.data.access_token) {
          setError(true)
        }else{
          localStorage.setItem('refresh_token',result_reset_token.data.access_token)
          
        }
      }catch(err){
        if(err){
          setError(true)
        }
      }
    }
    getTokenData(id)
    localStorage.removeItem('access_token')
  },[id])

  const [passwordOne, setPasswordOne] = useState(false)
  const [passwordTwo, setPasswordTwo] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      renter_password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
      .min(8, `${t('Password must be at least 8 characters long')}`)
      .matches(/[0-9]/, `${t('Password must contain at least one digit')}`)
      .required(`${t('Password is required')}`),
      renter_password: Yup.string().oneOf(
        [Yup.ref('password'), ''],
        `${t('Passwords must match')}`
      ),
    }),
    onSubmit: async (values) => {
      try {
        let data = { ...values }
        delete data.renter_password
        const results = await toast.promise(reenterpassword(data), {
          pending: 'Loading',
        })
        if (results.data.status === 0) {
          toast.error(results.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
            progressClassName: 'fancy-progress-bar',
          })
        } else if (results.data.status === 1) {
          toast.success(results.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          })
          navigate('/')
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
          progressClassName: 'fancy-progress-bar',
        })
        if(err){
           setError(true)
        }
      }
    },
  })
  return (
    <div className="confirmation_page">
      <div className="confirmation_card">
       {!error? <div className="confirmation_content">
          <Row>
            <Col className="login_container_logo">
              <img src={Logo} alt="logo" loading="lazy" />
            </Col>
          </Row>
          <Container className="g-0">
            <Row className="login_content_box no-gutters row-fluid g-0">
              <Row className="vechicle_modal_row g-0">
                <Col>
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group className="mb-3 position-relative">
                      <Form.Control
                        type={passwordOne ? 'text' : 'password'}
                        id="password"
                        placeholder={t('New Password')}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      <div
                        className="toggle_password"
                        onClick={() => setPasswordOne((prev) => !prev)}
                      >
                        <PasswordIcon
                          color={passwordOne ? '#B3B3B3' : 'black'}
                        />
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="form_errors">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative">
                      <Form.Control
                        type={passwordTwo ? 'text' : 'password'}
                        id="renter_password"
                        placeholder={t('Re-enter Password')}
                        value={formik.values.renter_password}
                        onChange={formik.handleChange}
                      />
                      <div
                        className="toggle_password"
                        onClick={() => setPasswordTwo((prev) => !prev)}
                      >
                        <PasswordIcon
                          color={passwordTwo ? '#B3B3B3' : 'black'}
                        />
                      </div>
                      {formik.touched.renter_password &&
                      formik.errors.renter_password ? (
                        <div className="form_errors">
                          {formik.errors.renter_password}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100 my-1 add_vechicle_submit_button"
                    >
                      {t('Submit')}
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Row>
          </Container>
        </div>:<NotFoundError/>}
      </div>
    </div>
  )
}

export default Reenterpassword
