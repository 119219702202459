import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function Usehook(apiFn) {
  const navigate=useNavigate()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const getData = async () => {
      try{
        const response = await apiFn()
         if(response){
          setData(response.data.data)
          setLoading(false)
         }  
      }
      catch(err){
          console.log(err)
          if(err.response.status===401){
            setLoading(false)
             localStorage.removeItem('access_token')
             navigate('/login')

          }
      }
      
    }

    getData()
  },[apiFn])

  return { data:data,loading:loading }
}

export default Usehook
