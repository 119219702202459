import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

function Protected(props) {
  const { Component } = props

  const navigate = useNavigate()

  let location = useLocation()

  useEffect(() => {
    let isLogin = localStorage.getItem('access_token')
    if (!isLogin) {
      navigate('/login')
    } else {
      navigate(`${location.pathname}`)
    }
  }, [location.pathname, navigate])
  
  return (
    <div>
      <Component />
    </div>
  )
}

export default Protected
