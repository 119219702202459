import React from 'react'

function VechicleAddIcon() {
  return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <rect
        width="2.204"
        height="15.429"
        x="6.895"
        y="0.287"
        fill="#fff"
        rx="1.102"
      ></rect>
      <rect
        width="2.204"
        height="15.429"
        x="0.286"
        y="9.104"
        fill="#fff"
        rx="1.102"
        transform="rotate(-90 .286 9.104)"
      ></rect>
    </svg>
  )
}

export default VechicleAddIcon