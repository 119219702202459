import React from 'react'
import { Modal } from 'react-bootstrap'
import { useLanguageStore } from '../../Store/Store'
import { useTranslation } from 'react-i18next'

function DeleteModal(props) {
  const { show, handleClose, closeLogoutModal, handleDeleteConfirm } = props

  const currentLanguage = useLanguageStore((state) => state.currentLang)
  
  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={` logout_modal_container`}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={`${currentLanguage === 'ar' ? 'arabic' : 'english'}`}
    >
      <Modal.Body className={`edit_profile_modal my-2`}>
        <div className="mx-auto logout_modal_text">
          <p className="text-center">{t('Are you sure you want to delete this vehicle?')}</p>
        </div>

        <div className="logout_modal">
          <div className="logout_buttons">
            <button onClick={closeLogoutModal}>{t('Cancel')}</button>
          </div>

          <div className="logout_buttons">
            <button className="active" onClick={handleDeleteConfirm}>
              {t('Yes Sure')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteModal
