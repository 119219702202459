import React from 'react'
import ConfirmationMail from '../public/images/email.png'

const ConfirmationForgot = () => {
  return (
    <div className="confirmation_page">
      <div className="confirmation_card">
        <div className="confirmation_content">
          <div className="confirmation_mail_image">
            <img src={ConfirmationMail} alt="confiration Mail" loading="lazy" />
          </div>
          <div className="confirmation_text">
            <p>
              A confirmation link has been sent to your account please check
              your mail and login.
            </p>
          </div>
          </div>
    </div>
    </div>
  )
}

export default ConfirmationForgot