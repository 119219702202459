import React from 'react'
import { Row, Table, Container } from 'react-bootstrap'
import OrderDetails from '../Components/OrderDetails'
import Truckicon from '../svg/Truckicon'
import Orders from '../svg/Orders'
import Payments from '../svg/Payments'
import { useTranslation } from 'react-i18next'
import { indexpage } from '../../api'
import Usehook from '../../customhooks.js/usehook'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'
import moment from 'moment'


function DashBoardIndex() {
  const { t } = useTranslation()

  const { data,loading } = Usehook(indexpage)

  const navigate=useNavigate()

  return (
    <>
    {!loading ?
      <Container fluid className="sidbar_content_container animated_index">
        <Row className="p-8 justify-content-between m-0">
          <OrderDetails
            numbers={data?.order_today}
            detailsText={t('Orders Today')}
            icon={<Orders />}
            color={'#F48F31'}
          />

          <OrderDetails
            numbers={`$ ${data?.order_total}`}
            detailsText={t('Earning Today')}
            icon={<Payments />}
            color={'#F43177'}
          />

          <OrderDetails
            numbers={data?.vehicle_length}
            detailsText={t('Vehicles')}
            icon={<Truckicon />}
            color={'#40AE36'}
          />
        </Row>

        <Row className="bg-white p-2 sidebar_content_table">
          <div className="d-flex justify-content-between align-items-center py-4 px-2">
            <div className="sidebar_content_orders">{t('Recent Orders')}</div>
            {data.recent_orders.length>0?<div className="sidebar_content_view_all" onClick={()=>navigate('/orders')}>{t('View All')}</div>:<></>}
          </div>
          <Table responsive="lg" className='recent_order_table'>
            {data.recent_orders.length>0?<>
            <thead>
              <tr className="sidbar_content_table_other_row">
                <th>{t('Sr. No.')}</th>
                <th>{t('Order ID')}</th>
                <th>{t('Order Date')}</th>
                <th>{t('Vehicle Reg. no.')}</th>
                <th>{t('Status')}</th>
              </tr>
            </thead>
            <tbody>
              {data?.recent_orders?.map((curr, index) => {
                return (
                  <tr className="sidbar_content_table_other_row" key={index}>
                    <td>{index + 1} </td>
                    <td>{curr.order_id}</td>
                    <td>{moment(curr.time * 1000).format('D MMM. YYYY')}</td>
                    <td>{curr.registration_number}</td>
                    <td>
                      <span
                        className="dashboard_order_status"
                        style={{
                          background: `${
                            curr.order_status === 'Processing'
                              ? '#3164F41A'
                              : curr.order_status === 'Cancelled'
                              ? '#E20D0D1A'
                              : curr.order_status === 'Completed'
                              ? '#13A7341A'
                              : 'gray'
                          }`,
                          color:`${
                            curr.order_status === 'Processing'
                              ? '#3164F4'
                              : curr.order_status === 'Cancelled'
                              ? '#E20D0D'
                              : curr.order_status === 'Completed'
                              ? '#13A734'
                              : 'white'
                          }`
                        }}
                      >
                       {curr.order_status === 'Processing'
                        ? t('Processing')
                        : curr.order_status === 'Cancelled'
                        ? t('Cancelled')
                        : curr.order_status === 'Completed'
                        ? t('Completed')
                        : ''}
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            </>:<thead>
              <tr>
                <td colSpan="3" className="empty_table_data">
                  {t('No recent orders')}
                </td>
              </tr>
            </thead>}
          </Table>
        </Row>
      </Container>:<ReactLoading type={'spokes'} color="black" className={'loader_position'}/>}
    </>
  )
}

export default DashBoardIndex
