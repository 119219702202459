import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import Logo from '../public/images/image 1.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { enterPassword, getEmail } from '../api'
import { toast } from 'react-toastify'
import { useLanguageStore } from '../Store/Store'
import PasswordIcon from '../Dashboard/svg/PasswordIcon'
import NotFoundError from '../Dashboard/Components/NotFoundError'
import ReactLoading from 'react-loading'

function EnterPassword() {
  const { t } = useTranslation()
  let navigate = useNavigate()
  const { id } = useParams()
  const [passwordOne, setPasswordOne] = useState(false)

  const [email, setEmail] = useState('')

  const [error, setError] = useState(true)

  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const getEmailFn = async () => {
      try {
        const getEmailResponse = await getEmail(id)

        const { data, status } = getEmailResponse.data

        if (status === 1) {
          setEmail(data)

          setLoader(false)

          setError(false)
        } else {
          setLoader(false)
        }
      } catch (err) {
        setLoader(false)
        console.log(err)
      }
    }
    getEmailFn()
  }, [])

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(`${t('Required')}`),
    }),
    onSubmit: async (values) => {
      localStorage.removeItem('access_token')
      try {
        let data = {
          ...values,
          email: email,
        }
        const results = await toast.promise(enterPassword(data), {
          pending: 'Loading',
        })
        if (results.data.status === 0) {
          navigate('/confirmation')
        } else if (results.data.status === 1) {
          const access_token = results.data.data
          localStorage.setItem('access_token', access_token)
          navigate('/')
        }
      } catch (err) {
        if (err) {
          toast.error(err.response.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
            progressClassName: 'fancy-progress-bar',
          })
        }
      } finally {
      }
    },
  })

  const current_lang = useLanguageStore((state) => state.currentLang)

  return (
    <div className="confirmation_page">
      <div className="confirmation_card">
        <div className="confirmation_content" >
          {loader ? (
            <>
              {' '}
              <div className='enter_password_loader'>
              <ReactLoading
                type={'spokes'}
                color="black"
                className={'loader_position'}
              />
              </div>
            </>
          ) : !error && !loader ? (
            <>
              <Row>
                <Col className="login_container_logo">
                  <img src={Logo} alt="logo" loading="lazy" />
                </Col>
              </Row>
              <Form
                onSubmit={formik.handleSubmit}
                className="enter_password_login"
              >
                <Form.Group className="mb-3 position-relative">
                  <Form.Control
                    type={passwordOne ? 'text' : 'password'}
                    id="password"
                    placeholder={t('Password')}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className="enter_password_input"
                  />

                  <div
                    className={
                      current_lang === 'ar' 
                        ? `toggle_password_right`
                        : `toggle_password`
                    }
                    onClick={() => setPasswordOne((prev) => !prev)}
                  >
                    <PasswordIcon color={passwordOne ? '#B3B3B3' : 'black'} />
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100 my-1 enter_password_button"
                    // onClick={() => navigate('/home')}
                    // disabled={isSubmitting}
                  >
                    {/* {isResponse?<Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />:<></>} */}
                    {t('Login')}
                  </Button>
                </Form.Group>
              </Form>
            </>
          ) : (
            <>
              <NotFoundError />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default EnterPassword
