import React from 'react'

function AddIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="#fff"
      d="M9 13.167c.236 0 .434-.08.594-.24.16-.16.24-.358.24-.594v-2.5h2.52c.236 0 .43-.08.584-.24A.826.826 0 0013.167 9a.807.807 0 00-.24-.594.803.803 0 00-.594-.24h-2.5v-2.52c0-.236-.08-.43-.24-.583A.826.826 0 009 4.833a.807.807 0 00-.594.24.803.803 0 00-.24.594v2.5h-2.52c-.236 0-.43.08-.583.24a.826.826 0 00-.23.593c0 .236.08.434.24.594.16.16.358.24.594.24h2.5v2.52c0 .236.08.43.24.584.16.152.358.229.593.229zm0 4.166a8.11 8.11 0 01-3.25-.656 8.43 8.43 0 01-2.646-1.781 8.41 8.41 0 01-1.78-2.646A8.13 8.13 0 01.666 9a8.11 8.11 0 01.656-3.25 8.428 8.428 0 011.781-2.646 8.41 8.41 0 012.646-1.78A8.13 8.13 0 019 .666a8.11 8.11 0 013.25.656 8.428 8.428 0 012.646 1.781 8.422 8.422 0 011.782 2.646A8.099 8.099 0 0117.333 9a8.11 8.11 0 01-.656 3.25 8.426 8.426 0 01-1.781 2.646 8.421 8.421 0 01-2.646 1.781 8.1 8.1 0 01-3.25.656zm0-1.666c1.847 0 3.42-.65 4.72-1.948 1.298-1.298 1.947-2.871 1.947-4.719 0-1.847-.65-3.42-1.948-4.72C12.421 2.983 10.848 2.334 9 2.334c-1.847 0-3.42.65-4.72 1.948C2.983 5.579 2.334 7.152 2.334 9c0 1.847.65 3.42 1.948 4.72 1.298 1.298 2.871 1.947 4.72 1.947z"
    ></path>
  </svg>
  )
}

export default AddIcon