import React from 'react'
import VechicleTruckIcon from '../svg/VechicleTruckIcon'
import CrossIcon from '../svg/CrossIcon'
import { useTranslation } from 'react-i18next'
import {  Modal } from 'react-bootstrap'
import EditVehicleForm from './EditVehicleForm'

const EditVehicleModal = (props) => {
    const { show, handleClose, closeModal }=props

    const { t } = useTranslation()

  return (
    <>
    <Modal show={show} onHide={handleClose} className='edit_vehicle_modal '>
      <div closeButton
        className="vechicle_modal_close_button"
        onClick={closeModal}
      >
        <CrossIcon />
      </div>

      <div>
        <div className="d-flex vechicle_modal">
          <div className="sidebar_content_round">
            <div
              className="sidbar_content_icons_round vechicle"
              style={{ backgroundColor: `#3164F4` }}
            >
              <VechicleTruckIcon />
            </div>
          </div>

          <div className="vechicle_modal_add">
            <p>{t('Edit Vehicle')}</p>
          </div>
          <div className="vechicle_modal_form">
            <EditVehicleForm {...props}/>
          </div>
        </div>
      </div>
      </Modal>
    </>
  )
}

export default EditVehicleModal