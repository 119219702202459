import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Logo from '../public/images/image 1.png'
import './login.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { login } from '../api'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { withTranslation } from 'react-i18next'
import PasswordIcon from '../Dashboard/svg/PasswordIcon'
import { useLanguageStore } from '../Store/Store'
function Login({ i18n }) {
  const { t } = useTranslation()

  const [passwordOne, setPasswordOne] = useState(false)

  let navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`${t('Invalid email address')}`)
        .required(`${t('Required')}`),
      password: Yup.string().required(`${t('Password is required')}`),
    }),
    onSubmit: async (values) => {
      localStorage.removeItem('access_token')
      try {
        let data = {
          ...values,
        }
        const results = await toast.promise(login(data), {
          pending: 'Loading',
        })
        if (results.data.status === 0) {
          toast.error(data.data.message, {
            position: 'top-center',
          })
        } else if (results.data.status === 1) {
          const access_token = results.data.data
          localStorage.setItem('access_token', access_token)
          localStorage.removeItem("user_email");
          navigate('/')
        }
      } catch (err) {
        console.log(err)
        if (err.response.status === 403) {
          navigate('/confirmation')
        }
        toast.error(err.response.data.message, {
          position: 'top-center',
        })
      } finally {
      }
    },
  })

  const current_lang = useLanguageStore((state) => state.currentLang)

  return (
    <div className="login animated_auth">
      <Container fluid="md login_container">
        <Row className="login_content_box g-0 row-fluid px-4">
          <Row className="g-0">
            <Col className="login_container_logo">
              <img src={Logo} alt="" loading="lazy" />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={t('Email')}
                    id="email"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="form_errors">{formik.errors.email}</div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 position-relative">
                  <Form.Control
                    type={passwordOne ? 'text' : 'password'}
                    id="password"
                    placeholder={t('Password')}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  <div
                    className={
                      current_lang === 'ar'
                        ? `toggle_password_right`
                        : `toggle_password`
                    }
                    onClick={() => setPasswordOne((prev) => !prev)}
                  >
                    <PasswordIcon color={passwordOne ? '#B3B3B3' : 'black'} />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="form_errors">{formik.errors.password}</div>
                  ) : null}
                </Form.Group>
                <div
                  className="text-right login_container_forgotpassword"
                  onClick={() => navigate('/forgotpassword')}
                >
                  <p>{t('Forgot Password ?')}</p>
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 my-1 login_container_button"
                >
                  {t('Login')}
                </Button>

                <div className="py-2 mx-auto text-center login_container_text">
                  <p>
                    {t('Don’t have an account ?')}{' '}
                    <span
                      className="login_container_signup"
                      onClick={() => navigate('/signup')}
                    >
                      {t('Signup')}
                    </span>
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(Login)
