import React from 'react'
import { Container, Row } from 'react-bootstrap'
import PaymentsCard from '../Components/PaymentsCard'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Usehook from '../../customhooks.js/usehook'
import { getPaymentDetails, makePayment } from '../../api'
import { useState } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading'
import LineLoading from '../Components/LineLoading'

function PaymentsIndex() {
  const { t } = useTranslation()

  const [total, setTotal] = useState(0)

  const [paymentLoading, setPaymentLoading] = useState(false)

  const { data, loading } = Usehook(getPaymentDetails)

  useEffect(() => {
    setTotal(data)
  }, [data])

  const handlePaymentSubmit = async () => {
    if (total.left_amount <= 100) {
      toast.error('Too Less amount')
      return
    }

    setPaymentLoading(true)
    let payload = { price: total.left_amount }

    try {
      const makePaymentResponse = await makePayment(payload)

      const { data, status } = makePaymentResponse.data

      if (status === 1) {
        setPaymentLoading(false)

        window.open(`${data}`, '_self')
      }
    } catch (err) {
      setPaymentLoading(false)

      toast.error('Something went wrong')
      console.log(err)
    }
  }

  console.log('Total=>',total)

  return (
    <>
      {!loading ? (
        <Container
          className="payments_index position-relative animated_index"
          fluid
        >
          <h3 className="payments_index_payment_text"> {t('Payments')} </h3>
          <Row className="payment_card_container">
            <div className="payment_card_content">
              {!paymentLoading ? (
                <PaymentsCard
                  orderCount={total.totalOrders}
                  commissionDue={total.left_amount / 100}
                 
                  handlePaymentSubmit={handlePaymentSubmit}
                />
              ) : (
                <LineLoading />
              )}
            </div>
          </Row>
        </Container>
      ) : (
        <ReactLoading
          type={'spokes'}
          color="black"
          className={'loader_position'}
        />
      )}
    </>
  )
}

export default PaymentsIndex
