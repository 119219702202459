import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools';

const initialState={
  image: '',
  id: '',
  email: '',
  name: '',
  phone_number:'',
  company_name:''
}
export const useLanguageStore = create((set) => ({
  key: '',
  currentLang: 'en',
  userData: initialState,
  changeKey: () => set((state) => ({ key: Math.random() })),
  changeLanguage: (language) => set((state) => ({ currentLang: language })),
  setUserData: (data) =>
    set((state) => ({
      userData:{...state.userData,image:data?.image,
        user_id: data?.user_id,
        email: data?.email,
        name: data?.name,
        phone_number:data?.phone_number,
        company_name:data?.company_name
      }
    })),
  
}))
mountStoreDevtool('Store', useLanguageStore);

// company_image: data.image,
//       company_id: data.u_id,
//       company_email: data.email,
//       company_name: data.data.name,
//       company_phone_number: data._phone_number,