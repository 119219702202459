import React from 'react'

function Orders() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12.909 0c3.144 0 5.256 2.153 5.256 5.357v9.196c0 3.232-2.047 5.334-5.215 5.354l-7.693.003C2.113 19.91 0 17.757 0 14.553V5.357C0 2.124 2.047.023 5.215.004L12.908 0zm0 1.5l-7.69.004C2.893 1.518 1.5 2.958 1.5 5.357v9.196c0 2.415 1.406 3.857 3.757 3.857l7.689-.003c2.328-.014 3.72-1.456 3.72-3.854V5.357c0-2.415-1.404-3.857-3.756-3.857zm-.193 11.974a.75.75 0 010 1.5h-7.22a.75.75 0 010-1.5h7.22zm0-4.187a.75.75 0 010 1.5h-7.22a.75.75 0 010-1.5h7.22zM8.25 5.11a.75.75 0 010 1.5H5.495a.75.75 0 010-1.5H8.25z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Orders