import React from 'react'

function SidebarOptions(props) {
  const { option, icon,isActive } = props
  return (
    <div className={`${isActive ? 'side_bar_active':''} px-2 py-2 sidbar_content_menu_item align-items-baseline`} >
      <div>{icon}</div>
      <div className='px-2'>
        <p className="sidbar_content_menu_text">{option}</p>
      </div>
    </div>
  )
}

export default SidebarOptions
