import React from 'react'

function EditIcons() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="none"
    viewBox="0 -0.5 25 25"
  >
    <path
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17.7 5.128l1.566 1.247a.748.748 0 01-.006 1.095l-1.19 1.424-4.049 4.829a.517.517 0 01-.27.163l-2.1.476a.529.529 0 01-.551-.46v-2.158a.464.464 0 01.119-.28l3.974-4.493 1.364-1.63a.868.868 0 011.143-.213z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      d="M12.033 7.619a.75.75 0 000-1.5v1.5zm-2.8-.75v-.75h-.002l.002.75zM5.5 10.619h.75v-.002l-.75.002zm0 5.625l.75.001v-.001H5.5zm3.733 3.75l-.002.75h.002v-.75zm5.6 0v.75h.002l-.002-.75zm3.733-3.75h-.75v.001l.75-.001zm.75-3.75a.75.75 0 10-1.5 0h1.5zm-3.43-5.81a.75.75 0 00-1.386.573l1.386-.573zm2.346 2.938a.75.75 0 00-.324-1.465l.324 1.465zM8.3 16.432a.75.75 0 100 1.5v-1.5zm7.467 1.5a.75.75 0 000-1.5v1.5zM12.033 6.119h-2.8v1.5h2.8v-1.5zm-2.802 0A4.492 4.492 0 004.75 10.62l1.5-.003a2.992 2.992 0 012.985-2.998l-.004-1.5zm-4.481 4.5v5.625h1.5v-5.625h-1.5zm0 5.623a4.492 4.492 0 004.481 4.502l.004-1.5a2.992 2.992 0 01-2.985-2.999l-1.5-.003zm4.483 4.502h5.6v-1.5h-5.6v1.5zm5.602 0a4.492 4.492 0 004.481-4.502l-1.5.003a2.992 2.992 0 01-2.985 2.999l.004 1.5zm4.481-4.5v-3.75h-1.5v3.75h1.5zM14.5 7.257a4.653 4.653 0 001.187 1.658c.607.53 1.48.942 2.545.707l-.324-1.465c-.465.103-.869-.053-1.237-.373a3.16 3.16 0 01-.785-1.1l-1.386.573zM8.3 17.932h7.467v-1.5H8.3v1.5z"
    ></path>
  </svg>
  )
}

export default EditIcons