import React from 'react'
import dummyimage from '../../public/images/dumyy.jpg'
import { Card } from 'react-bootstrap'
import ReactLoading from 'react-loading'

const ProfileDetailsSmaill = (props) => {
    const { image, loading } = props

  return (
    <div> {!loading?<Card.Img
        variant="top"
        src={image ? image : dummyimage}
        className="company_profile_image_small"
      />:<ReactLoading type={'spokes'} color="black" className={'loader_position_profile_image'}/>}</div>
  )
}

export default ProfileDetailsSmaill

