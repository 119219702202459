import  {http}  from "../utils";
import axios from "axios";

export function loginUser(data) {
    var urlencoded = new URLSearchParams();

    urlencoded.append("client_id", "7f497975-8b7e-4495-9da6-906e7dc262a1");

    urlencoded.append("client_secret", "adore");

    urlencoded.append("grant_type", "password");

    urlencoded.append("username", data.username);

    urlencoded.append("password", data.password);

    return http.post('/oauth/token', urlencoded, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}
export function signUpUser(data) {
    return http.post('/signupcompany', data)
}
export function login(data) {
    return http.post('/logincompany',data)
}
export function getmyinfo() {
    return http.get('/getprofile')
}
export function uploadimage(formData,url) {
    return http.post(`/addimages/${url}`,formData)
}

export function deleteimage(formData,url,id) {
    return http.post(`/addimages/${url}?to_remove=${id}`,formData)
}

export function updateprofile(data) {
    return http.patch('/updateprofile',data)
}

export function resetpassword(data) {
    return http.patch('/resetpassword',data)
}

export function addvehicle(data) {
    return http.post('/addvehicle',data)
}

export function getvehicle() {
    return http.get('/getvehicle')
}

export function getorders() {
    return http.get('/orderdetails')
}

export function indexpage() {
    return http.get('/dashboardinfo')
}

export function forgotpassword(values) {
    return http.patch('/forgotpassword',values)
}

export function resettoken(id) {
    return http.get(`/resettoken/${id}`)
}


export function reenterpassword(data) {
    let token=localStorage.getItem("refresh_token")
    let refresh_token=''
    if(token){
        refresh_token=token
    }
    else{
        refresh_token=''
    }
    return axios.patch('https://hammerhead-app-8ng3z.ondigitalocean.app/api/reenterpassword',data,{
        headers: {
          'Authorization': `Bearer ${refresh_token}` 
        }
    })
}


export function updateVehicle(data,id) {
    return http.patch(`/edit/company/vehicle/${id}`,data)
}

export function getCompanyVehicle(id){
    return http.get(`/get/company/vehicle/${id}`)
}

export function deleteVehicle(id){
    return http.delete(`/delete/company/vehicle/${id}`)
} 

export function getPaymentDetails(){
    return http.get(`/get/commission/details`)
} 

export function makePayment(data){
    return http.post(`/payment`,data)
}

export function getEmail(id){
    return http.get(`/getemail/${id}`)
}
export function enterPassword(data){
    return http.patch(`/enter/password`,data)
}

export function ResendOtp(data){
    return http.post(`/resend/link`,data)
}

// "email":"bhadwalabhishek7@gmail.com",
//     "name":"abhishekKashyap",
//     "company_name":"TECH Response",
//     "phone_number":"9018736272",
//     "password":"abhihsek@78",