import React from 'react'
import errorImage from '../../public/images/404-error-page.jpg'

const NotFoundError = () => {
  return (
    <div className='error_image_not_found'>
         <img src={errorImage} alt='error_page'/>
    </div>
  )
}

export default NotFoundError