import React from 'react'

function EditIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill="white"
      fillRule="evenodd"
      d="M13.157 2.497a.704.704 0 010 .997l-1.294 1.294-2.651-2.651L10.506.843a.704.704 0 01.996 0l1.655 1.654zM.637 13.01v-2.15c0-.098.035-.183.105-.254l7.713-7.713 2.652 2.652-7.72 7.713a.332.332 0 01-.248.106H.989a.35.35 0 01-.353-.354z"
      clipRule="evenodd"
    ></path>
  </svg>
  )
}

export default EditIcon