import { useFormik } from 'formik'
import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Logo from '../public/images/image 1.png'
import './login.css'
import { forgotpassword } from '../api'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

function ForgotPassword() {
  const { t } = useTranslation()
  let navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`${t('Invalid email address')}`)
        .required(`${t('Required')}`),
    }),
    onSubmit: async (values) => {
      try {
        const results = await toast.promise(forgotpassword(values), {
          pending: 'Loading',
        })
        if (results.data.data.status === 0) {
          toast.error(results.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
            progressClassName: 'fancy-progress-bar',
          })
        } else if (results.data.status === 1) {
          navigate('/confirmation/forgot')
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
          progressClassName: 'fancy-progress-bar',
        })
      } finally {
      }
    },
  })
  return (
    <div className="confirmation_page">
      <div className="confirmation_card">
        <Row>
          <Col className="px-4">
            <h6>Forgot Password</h6>
          </Col>
        </Row>

        <div className="confirmation_content">
          <Row>
            <Col className="login_container_logo">
              <img src={Logo} alt="logo" loading="lazy" />
            </Col>
          </Row>
          <Form onSubmit={formik.handleSubmit} className="enter_password_login">
            <Form.Group className="mb-3">
              <Form.Control
                type="email "
                id="email"
                placeholder={t('Email')}
                value={formik.values.password}
                onChange={formik.handleChange}
                className="enter_password_input"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="form_errors">{formik.errors.email}</div>
              ) : null}
              <Button
                variant="primary"
                type="submit"
                className="w-100 my-1 enter_password_button"
              >
                {t('Submit')}
              </Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
