import { Col, Container, Row } from 'react-bootstrap'
import Dashicon from '../svg/Dashicon'
import Orders from '../svg/Orders'
import Payments from '../svg/Payments'
import Truckicon from '../svg/Truckicon'
import ProfileDetails from './ProfileDetails'
import ProfileDetailsSmaill from './ProfileDetailsSmaill'
import { Link } from 'react-router-dom'

function BottomBar(props) {
  const { userData, loading, pathName } = props

  return (
    <Container className="bottom_bar" fluid="md">
      <Row className="d-flex justify-content-between align-items-center">
        <Col className="mobile_screen_icons"  xs={2} sm={2}>
          <Link
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? 'pending' : isActive ? 'active' : ''
            }
          >
            <div
              className={`${
                pathName === '/' ? ' active_icons' : ''
              } small_icons`}
            >
              <Dashicon />
            </div>
          </Link>
        </Col>
        <Col className="mobile_screen_icons" xs={2} sm={2}>
          <Link
            to="/vehicle"
            className={({ isActive, isPending }) =>
              isPending ? 'pending' : isActive ? 'active' : ''
            }
          >
            <div
              className={`${
                pathName === '/vehicle' ? ' active_icons' : ''
              } small_icons`}
            >
              <Truckicon />
            </div>
          </Link>
        </Col>
        <Col className="mobile_screen_icons" xs={2} sm={2}>
          <Link to="/orders">
            <div
              className={`${
                pathName === '/orders' ? ' active_icons' : ''
              } small_icons`}
            >
              <Orders />
            </div>
          </Link>
        </Col>
        <Col className="mobile_screen_icons" xs={2} sm={2}>
          <Link to="/payments">
            <div
              className={`${
                pathName === '/payments' ? ' active_icons' : ''
              } small_icons`}
            >
              <Payments />
            </div>
          </Link>
        </Col>
        <Col xs={2} sm={2}>
        <Link to="/editprofile">

          <div
            className={`${
              pathName === '/editprofile' ? ' active_icons' : ''
            } small_icons`}
          >
            <ProfileDetailsSmaill
              company_name={userData.company_name}
              image={userData.image}
              loading={loading}
            />
          </div>
          </Link>
          
        </Col>
      </Row>
    </Container>
  )
}

export default BottomBar
