import axios from "axios";

const http = axios.create({
    baseURL: 'https://hammerhead-app-8ng3z.ondigitalocean.app/api/',
})

http.interceptors.request.use(request => {
    const access_token = localStorage.getItem('access_token')

    if (access_token) {
        request.headers.Authorization = `Bearer ${access_token}`
    }

    return request
})

export default http