import React, { useEffect, useState } from 'react'
import ConfirmationMail from '../public/images/email.png'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { ResendOtp } from '../api'
import { toast } from 'react-toastify'
function Confirmation(props) {
  const { t } = useTranslation()
  const [timeRemaining, setTimeRemaining] = useState(30)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const email = sessionStorage.getItem('user_email')
  useEffect(() => {
    let interval

    if (timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1)
      }, 1000)
    } else {
      setIsButtonEnabled(true)
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [timeRemaining, email])

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }

  const handleAgainLink = async () => {
    let payload = { email: email }

    try {
      const resposne = await ResendOtp(payload)
      if (resposne.data.status === 1) {
        setTimeRemaining(30)
        setIsButtonEnabled(false)
        toast.success('Email sent successfully')
      }
      console.log(resposne)
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong')
    }
  }

  return (
    <div className="confirmation_page">
      <div className="confirmation_card">
        <div className="confirmation_content">
          <div className="confirmation_mail_image">
            <img src={ConfirmationMail} alt="confiration Mail" loading="lazy" />
          </div>
          <div className="confirmation_text">
            <p>
              A confirmation link has been sent to your account please check
              your mail and login.
            </p>
          </div>

          {email && (
            <div className="py-2 mx-auto text-center login_container_text">
              <Button
                variant="primary"
                type="submit"
                className="w-100 my-1 login_container_button px-4 py-2 cursor-pointer"
                disabled={!isButtonEnabled}
                onClick={handleAgainLink}
              >
                {t('Resend')}
              </Button>

              {timeRemaining > 0 && (
                <div className="smaller_text">
                  You can request after: {formatTime(timeRemaining)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Confirmation
