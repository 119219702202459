import { Button, Row, Form, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import RightArrowIcon from '../svg/RightArrowIcon'
import EditIcon from '../svg/EditIcon'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { updateprofile } from '../../api'
import dummycompany from '../../public/images/house-g1cc5a9e18_640.png'
import { useLanguageStore } from '../../Store/Store'

function EditProfileCard(props) {
  const { forgotModal, handleProfileClick, onSelectFile, imageRef, user_data } =
    props

  const userData = useLanguageStore((state) => state.setUserData)

  const { t } = useTranslation()

  const display_phone_number = user_data?.phone_number

  const user_phone_number = display_phone_number?.slice(-10)

  const user_dial_code = display_phone_number?.slice(
    0,
    display_phone_number.length - user_phone_number.length
  )

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formik = useFormik({
    initialValues: {
      name: user_data?.name || '',
      company_name: user_data?.company_name || '',
      email: user_data?.email || '',
      phone_number: user_phone_number || '',
      dialcode: user_dial_code || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .min(4, `${t('Name must have 4 characters')}`)
        .max(20, `${t('Name must have 20 character or less')}`)
        .required(`${t('Required')}`),
      company_name: Yup.string()
        .max(30, `${t('يجب أن يتكون اسم الشركة من 30 حرفًا أو أقل')}`)
        .required(`${t('Required')}`),
      email: Yup.string()
        .email(`${t('Invalid email address')}`)
        .required(`${t('Required')}`),
      phone_number: Yup.string()
        .matches(phoneRegExp, `${t('Phone number is not valid')}`)
        .min(10, `${t('Phone number is not valid')}`)
        .max(10, `${t('Phone number is not valid')}`),
      dialcode: Yup.string().required(`${t('Please select an option')}`),
    }),
    onSubmit: async (values) => {
      try {
        const user_data = new Map()
        user_data.set('company_name', values.company_name)
        user_data.set('phone_number', values.phone_number)
        user_data.set('email', values.email)
        user_data.set(
          'phone_number',
          `${values.dialcode}${values.phone_number}`
        )
        user_data.set('password', values.password)
        user_data.set('name', values.name)
        const send_user_data = Object.fromEntries(user_data.entries())

        const data = await toast.promise(updateprofile(send_user_data), {
          pending: 'Loading',
        })
        if (data.data.status === 0) {
          toast.error(data.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
            progressClassName: 'fancy-progress-bar',
          })
        } else if (data.data.status === 1) {
          if (data.data.message === 'Data Updated Successfully') {
            userData(data.data.data)
          }
          toast.success(data.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
  })


  const current_lang = useLanguageStore((state) => state.currentLang)

  console.log('test user=>',user_data?.image)
  
  return (
    <>
      <Row className="payment_card_container">
        <div className="edit_card_content">
          <div className="">
            <Row>
              <Col
                className="edit_profile_company_logo position-relative cursor-pointer g-0"
                onClick={handleProfileClick}
              >
                <img
                  src={user_data?.image ? user_data.image : dummycompany}
                  alt=""
                  loading="lazy"
                />
                <div className="edit_profile_edit_icon">
                  <EditIcon />
                </div>
                <input
                  className="form-control d-none"
                  type="file"
                  id="formFileMultiple"
                  multiple
                  ref={imageRef}
                  onChange={onSelectFile}
                  accept="image/*"
                />
              </Col>
            </Row>
          </div>

          <Row className="w-100 edit_profile_card_form_container">
            <Col>
              <p className="edit_profile_basic_details">{t('Basic Details')}</p>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="name"
                    placeholder={t('Name')}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="form-control"
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="form_errors">{formik.errors.name}</div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="company_name"
                    placeholder={t('Company Name')}
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.company_name && formik.errors.company_name ? (
                    <div className="form_errors">
                      {formik.errors.company_name}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder={t('Email')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    disabled
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="form_errors">{formik.errors.email}</div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 position-relative">
                  <Form.Select
                    aria-label="Default select example"
                    className="signup_drop_down_menu"
                    onChange={formik.handleChange}
                    id="dialcode"
                    disabled
                  >
                    {user_dial_code ? (
                      <option value={''} key={'0'}>{user_dial_code || ''}</option>
                    ) : (
                      <option value="" key={78}>{t('Select')}</option>
                    )}
                    {/* {all_countries.map((countries, index) => {
                      return (
                        <>
                          <option
                            value={countries.data.dial_code}
                            key={index+1}
                            className="countries_code_select"
                          >
                            {countries.data.name}{' '}
                          </option>
                        </>
                      )
                    })} */}
                  </Form.Select>
                  <Form.Control
                    type="text"
                    id="phone_number"
                    placeholder={t('Phone Number')}
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    className={`
                      w-75 signup_phone_number ${
                        current_lang === 'ar'
                          ? `signup_phone_number_right`
                          : `signup_phone_number_left`
                      } `}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="form_errors">
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                  {formik.touched.dialcode && formik.errors.dialcode ? (
                    <div className="form_errors">{formik.errors.dialcode}</div>
                  ) : null}
                </Form.Group>

                <div
                  className="edit_profile_card_change_password"
                  onClick={forgotModal}
                >
                  <div>{t('Change Password')}</div>
                  <div>
                    <RightArrowIcon />
                  </div>
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 my-2 login_container_button"
                >
                  {t('Save Changes')}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </Row>
    </>
  )
}

export default EditProfileCard
