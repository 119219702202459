import React from 'react'

function Truckicon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="19"
    fill="none"
    viewBox="0 0 24 19"
  >
    <path
      fill="#040404"
      d="M0 2.25A2.25 2.25 0 012.25 0h13.5A2.25 2.25 0 0118 2.25V4.5h1.53a2.25 2.25 0 011.755.845l2.221 2.775c.32.399.494.895.494 1.407v3.223A2.25 2.25 0 0121.75 15H21a3 3 0 01-6 0H7.5a3 3 0 11-5.997-.127A2.25 2.25 0 010 12.75V2.25zm1.941 11.184a3 3 0 015.157.066h8.304a3.016 3.016 0 011.098-1.098V2.25a.75.75 0 00-.75-.75H2.25a.75.75 0 00-.75.75v10.5a.75.75 0 00.441.684zM18 12a3 3 0 012.598 1.5h1.152a.75.75 0 00.75-.75V9.525a.75.75 0 00-.165-.468l-2.22-2.775A.75.75 0 0019.53 6H18v6zM4.5 13.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm13.5 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
    ></path>
  </svg>
  )
}

export default Truckicon