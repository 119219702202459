import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { resetpassword } from '../../api'
import PasswordIcon from '../svg/PasswordIcon'
import { useLanguageStore } from '../../Store/Store'

function ChangePasswordForm(props) {
  const {handleClose}=props
  
  const { t } = useTranslation()

  const [passwordOne, setPasswordOne] = useState(false)

  const [passwordTwo, setPasswordTwo] = useState(false)
  
  const [passwordThree, setPasswordThree] = useState(false)

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      renter_password: '',
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required(`${t('Password is required')}`),
      new_password: Yup.string()
        .notOneOf(
          [Yup.ref('old_password')],
          `${t('New password must be different from previous password')}`
        )
        .required(`${t('New password must be different from previous password')}`),
      renter_password: Yup.string().oneOf(
        [Yup.ref('new_password'), ''],
        `${t('Passwords must match')}`
      ),
    }),
    onSubmit: async (values) => {
      try {
        let data = { ...values }
        delete data.renter_password
        const results = await toast.promise(resetpassword(data), {
          pending: 'Loading',
        })
        if (results.data.status === 0) {
          toast.error(results.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
            progressClassName: 'fancy-progress-bar',
          })
        } else if (results.data.status === 1) {
          handleClose()
          toast.success(results.data.message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'light',
          })
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
          progressClassName: 'fancy-progress-bar',
        })
      }
    },
  })

  const current_lang = useLanguageStore((state) => state.currentLang)
 

  return (
    <>
      <Container className="g-0">
        <Row className="login_content_box no-gutters row-fluid g-0">
          <Row className="vechicle_modal_row g-0">
            <Col>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3 position-relative">
                  <Form.Control
                    type={passwordOne ? 'text' : 'password'}
                    placeholder={t('Current Password')}
                    id="old_password"
                    value={formik.values.old_password}
                    onChange={formik.handleChange}
                  />
                  <div
                   className={
                    current_lang === 'ar' 
                      ? `toggle_password_right`
                      : `toggle_password`
                  }
                    onClick={() => setPasswordOne((prev) => !prev)}
                  >
                    <PasswordIcon color={passwordOne ? '#B3B3B3' : 'black'} />
                  </div>
                  {formik.touched.old_password && formik.errors.old_password ? (
                    <div className="form_errors">
                      {formik.errors.old_password}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 position-relative">
                  <Form.Control
                    type={passwordTwo ? 'text' : 'password'}
                    id="new_password"
                    placeholder={t('New Password')}
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                  />
                  <div
                    className={
                      current_lang === 'ar' 
                        ? `toggle_password_right`
                        : `toggle_password`
                    }
                    onClick={() => setPasswordTwo((prev) => !prev)}
                  >
                    <PasswordIcon color={passwordTwo ? '#B3B3B3' : 'black'} />
                  </div>
                  {formik.touched.new_password && formik.errors.new_password ? (
                    <div className="form_errors">
                      {formik.errors.new_password}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3 position-relative">
                  <Form.Control
                    type={passwordThree ? 'text' : 'password'}
                    id="renter_password"
                    placeholder={t('Re-enter Password')}
                    value={formik.values.renter_password}
                    onChange={formik.handleChange}
                  />
                  <div
                   className={
                    current_lang === 'ar' 
                      ? `toggle_password_right`
                      : `toggle_password`
                  }
                    onClick={() => setPasswordThree((prev) => !prev)}
                  >
                    <PasswordIcon color={passwordThree ? '#B3B3B3' : 'black'} />
                  </div>
                  {formik.touched.renter_password &&
                  formik.errors.renter_password ? (
                    <div className="form_errors">
                      {formik.errors.renter_password}
                    </div>
                  ) : null}
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 my-1 add_vechicle_submit_button"
                >
                  {t('Submit')}
                </Button>
              </Form>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  )
}

export default ChangePasswordForm
