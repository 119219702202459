import React, { useContext } from 'react'
import { Container, Navbar, NavDropdown } from 'react-bootstrap'
import LogoSmall from '../../public/images/image 1 (2).png'
import ExitIcon from '../svg/ExitIcon'
import LocaleContext from '../../LocaleContext'
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLanguageStore } from '../../Store/Store'

function NavbarComponent(props) {
  const { logoutModal } = props

  const { locale } = useContext(LocaleContext)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const changeKeys = useLanguageStore((state) => state.changeKey)
  const changeLanguage = useLanguageStore((state) => state.changeLanguage)

  function changeLocale(l) {
    i18n.changeLanguage(l)
    changeKeys()
    changeLanguage(l)
  }

  return (
    <>
      <Container fluid className="no-gutters p-0">
        <Navbar
          expand="lg"
          variant="light"
          bg="white"
          className={
            locale === 'en' ? 'ms-auto nav_navbar' : 'me-auto nav_navbar'
          }
        >
          <Container fluid>
            <Navbar.Brand onClick={() => navigate('/')}>
              <div className="d-flex align-items-baseline cursor-pointer">
                <div className="login_container_logo">
                  <img src={LogoSmall} alt="logo" loading="lazy"/>
                </div>

                <div className="nav_text">
                  <p>GEEB 8AAZ</p>
                </div>
              </div>
            </Navbar.Brand>

            <div className="cursor-pointer nav_header_icons">
              <div className="px-2">
                <NavDropdown title={t('language')} id="basic-nav-dropdown">
                  <NavDropdown.Item href="#" onClick={() => changeLocale('en')}>
                    English
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={() => changeLocale('ar')}>
                    العربية
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
              <div onClick={logoutModal} className="px-3">
                <ExitIcon />
              </div>
            </div>
          </Container>
        </Navbar>
      </Container>
    </>
  )
}

export default NavbarComponent
