import React, { useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import EditProfileCard from '../Components/EditProfileCard'
import { useTranslation } from 'react-i18next'
import { uploadimage } from '../../api'
import { toast } from 'react-toastify'
import ChangePasswordModal from '../Components/ChangePasswordFormModal'
import { useLanguageStore } from '../../Store/Store'

export const EditProfileIndex = (props) => {
  const [show, setShow] = useState(false)

  const { t } = useTranslation()

  const handleClose = () => setShow(false)

  const userData = useLanguageStore((state) => state.setUserData)

  const user_data_store = useLanguageStore((state) => state.userData)

  const { user_data } = props

  const imageRef = useRef(null)

  const handleProfileClick = () => {
    imageRef.current.click()
  }

  const onSelectFile = async (e) => {
    const image = e.target.files[0]
    if (!image) {
      return
    }

    const formData = new FormData()

    formData.append('image', image)

    formData.append('field', 'profile_images')

    if (formData) {
      const results = await toast.promise(
        uploadimage(formData, 'geeb_8aaz_profile_images'),
        {
          pending: 'Loading',
        }
      )

      if (results.data.status === 0) {
        toast.error(results.data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
          progressClassName: 'fancy-progress-bar',
        })
      } else if (results.data.status === 1) {
        const image = results.data.data.image
        let data = { ...user_data_store, image: image }
        userData(data)
        toast.success(results.data.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        })
      }
    }
  }

  return (
    <Container
      className="payments_index position-relative animated_index"
      fluid
    >
      <ChangePasswordModal
        show={show}
        handleClose={handleClose}
        closeModal={() => setShow(false)}
      />

      <h3 className="payments_index_payment_text"> {t('Edit Profile')} </h3>
      <EditProfileCard
        forgotModal={() => setShow(true)}
        handleProfileClick={handleProfileClick}
        onSelectFile={onSelectFile}
        imageRef={imageRef}
        user_data={user_data}
      />
    </Container>
  )
}
