import React from 'react'

function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#5B626B"
        d="M8.41 7l4.3-4.29a1.004 1.004 0 10-1.42-1.42L7 5.59l-4.29-4.3a1.004 1.004 0 00-1.42 1.42L5.59 7l-4.3 4.29a1 1 0 00.325 1.639 1 1 0 001.095-.219L7 8.41l4.29 4.3a1.001 1.001 0 001.639-.325 1 1 0 00-.22-1.095L8.41 7z"
      ></path>
    </svg>
  )
}

export default CrossIcon