import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import './dashboardindex.css'
import SidebarOptions from '../Components/SidebarOptions'
import NavbarComponent from '../Components/NavbarComponent'
import ProfileDetails from '../Components/ProfileDetails'
import OrdersIndex from './OrdersIndex'
import Truckicon from '../svg/Truckicon'
import Orders from '../svg/Orders'
import Dashicon from '../svg/Dashicon'
import Payments from '../svg/Payments'
import VechicleIndex from './VechicleIndex'
import {
  Route,
  Routes,
  NavLink,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import PaymentsIndex from './PaymentsIndex'
import { EditProfileIndex } from './EditProfileIndex'
import DashBoardIndex from './DashBoardIndex.js'
import { useTranslation } from 'react-i18next'
import LogoutModal from '../Components/LogoutModal'
import { useLanguageStore } from '../../Store/Store'
import Usehook from '../../customhooks.js/usehook'
import { getmyinfo } from '../../api'
import BottomBar from '../Components/Bottombar.js'

function Index() {
  const [show, setShow] = useState(false)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleClose = () => setShow(false)

  const { data, loading } = Usehook(getmyinfo)

  const logoutYes = () => {
    navigate('/login')
    localStorage.removeItem('access_token')
  }

  const get_user_data = data?.user_data

  const bears = useLanguageStore((state) => state.key)

  const userData = useLanguageStore((state) => state.setUserData)

  useEffect(() => {
    userData(get_user_data)
  }, [get_user_data, userData])

  const user_data = useLanguageStore((state) => state.userData)

  const location = useLocation()

  const pathName = location.pathname

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])


  return (
    <>
      <LogoutModal
        show={show}
        handleClose={handleClose}
        logoutYes={logoutYes}
        closeLogoutModal={() => setShow(false)}
      />
      <div key={bears}>
        <NavbarComponent logoutModal={() => setShow(true)} />
        {windowWidth < 768 ? <BottomBar userData={user_data} loading={loading} pathName={pathName}/> : <></>}
        <div className="d-flex animated_index">
          {windowWidth > 768 ? (
            <div className="sidebar_content">
              <Row className="no-gutters">
                <ProfileDetails
                  company_name={user_data.company_name}
                  image={user_data.image}
                  loading={loading}
                />

                <Row className="no-gutters px-3 py-4 mx-auto">
                  <Col>
                    <NavLink
                      to="/"
                      className={({ isActive, isPending }) =>
                        isPending ? 'pending' : isActive ? 'active' : ''
                      }
                    >
                      <SidebarOptions
                        icon={<Dashicon />}
                        option={t('Dashboard')}
                        isActive={pathName === '/'}
                      />
                    </NavLink>
                    <NavLink
                      to="/vehicle"
                      className={({ isActive, isPending }) =>
                        isPending ? 'pending' : isActive ? 'active' : ''
                      }
                    >
                      <SidebarOptions
                        icon={<Truckicon />}
                        option={t('Vehicles')}
                        isActive={pathName === '/vehicle'}
                      />
                    </NavLink>
                    <NavLink
                      to="/orders"
                      className={({ isActive, isPending }) =>
                        isPending ? 'pending' : isActive ? 'active' : ''
                      }
                    >
                      <SidebarOptions
                        icon={<Orders />}
                        option={t('Orders')}
                        isActive={pathName === '/orders'}
                      />
                    </NavLink>
                    <NavLink
                      to="/payments"
                      className={({ isActive, isPending }) =>
                        isPending ? 'pending' : isActive ? 'active' : ''
                      }
                    >
                      <SidebarOptions
                        icon={<Payments />}
                        option={t('Payments')}
                        isActive={pathName === '/payments'}
                      />
                    </NavLink>
                  </Col>
                </Row>
              </Row>
            </div>
          ) : (
            <></>
          )}

          <div className="dashboard_main_screen_vechicle">
            <Routes>
              <Route path="/" element={<DashBoardIndex />} />
              <Route path="/vehicle" element={<VechicleIndex />} />
              <Route path="/orders" element={<OrdersIndex />} />
              <Route path="/payments" element={<PaymentsIndex />} />
              <Route
                path="/editprofile"
                element={<EditProfileIndex user_data={user_data} />}
              />
            </Routes>

            <div className="main_screen_bootom_text">
              <p>geeb8aaz © 2023</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
